/* You can add global styles to this file, and also import other style files */
@import "primeicons/primeicons.css";
@import '~ngx-toastr/toastr';
@font-face {
    font-family: poppins;
    src: url(assets/fonts/Poppins/Poppins-Regular.ttf);
}
*{
   font-family: poppins;
   font-size: 0.8rem;
}
body{
    margin:0px;
    background: #F2F3F7;
    font-family: poppins;
    font-size: 0.8rem;
}
.blue-font{
    color:#015BA7;
}
.text-center{
    text-align: center;
}
.text-right{
    text-align: right;
}
.margin-zero{
    margin:0px;
}
.margin-top-2vh{
    margin:2vh 0px 0px 0px;
}
.margin-top-3vh{
    margin-top:3vh
}
.margin-top-5vh{
    margin:5vh 0px 0px 0px;
}
.margin-right-1point-5vw{
    margin-right: 1.5vw;
}
.margin-right-point-5vw{
    margin-right: 0.5vw;
}
.margin-right-point-75vw{
    margin-right: 0.75vw;
}
.display-flex{
    display: flex;
}
.display-block{
    display: block !important;
}
.align-items-center{
    align-items: center;
}
.justify-content-center{
    justify-content: center;
}
.cursor-pointer{
    cursor: pointer;
}
.padding-left-1vw{
    padding-left: 1vw;
}
.padding-left-point-5vw{
    padding-left: 0.5vw;
}
.rotate180{
    transform: rotate(180deg);
}
.user-select-none{
    user-select: none;
}
.content-padding{
    padding: 2vh 10vw;
}

.mat-step-header .mat-step-icon {
    background-color: #83BBE9 !important;
    color: #fff !important;
}
.mat-step-icon.mat-step-icon-state-number.mat-step-icon-selected{
    background-color: #529EDD !important;
}
.mat-step-text-label{
    font-weight: 600;
}
.mat-step-header:hover,.mat-horizontal-stepper-header.cdk-program-focussed{
background-color: #fff !important;
}
.mat-step-header.cdk-program-focused{
    background: rgba(37, 90, 162, 0.16);
}
.mat-horizontal-stepper-header{
    height: auto !important;
    padding: 10px 24px !important;
}
.display-block{
    display: block;
}
label{
    color: #5D6D7A;
    font-weight: 600;
    padding-bottom: 0.5rem;
    font-size: 0.85rem;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 0px;
    width: 100%;
    white-space: nowrap;
    position: relative;
    span.error{
        color: red;
        position: absolute;
        top: 0;
    }
}
input,select,.p-inputtext,.p-multiselect{
background: #F9FAFE;
border: 1.5px solid #DCE5F0;
border-radius: 5px;
padding: 0.6rem;
display: flex;
align-items: center;
font-size: 1rem;
box-sizing: border-box;
margin-right: 15px;
}
.p-calendar .p-button{
    color: #5D6D7A !important;
    background: #F9FAFE;
    border: 1px solid #DCE5F0;

}
.p-calendar{
    .p-inputtext{
        margin-right:0px !important;
    }
}
.p-dropdown{
    background: #F9FAFE;
}
.p-autocomplete {
    width: 100%;
}
.p-calendar .p-button:hover{
    background: #F9FAFE;
}

.p-inplace .p-inplace-content {
    display: flex !important;
  }
.width-100{
    width: 100%;
    .p-dropdown,.p-calendar,.p-multiselect,.p-autoComplete,.p-inputtext{
        width: 100%;
    }
}
.width-1 {
  width: 20rem;
}
.margin-top-zero{
    margin-top: 0px !important;
}
.margin-bottom-3vh{
    margin-bottom: 3vh;
}
.flex-80{
    flex: 0 0 80%;
}
.flex-20{
    flex:0 0 20%;
}
.flex-70{
    flex:0 0 70%;
}
.flex-30{
    flex: 0 0 30%;
}
.flex-end{
    justify-content: flex-end;
}
.mat-slide-toggle.mat-accent.mat-checked .mat-slide-toggle-bar {
    background-color: #015BA7 !important;
}
.mat-slide-toggle-bar{
    height: 22px !important;
    border-radius: 12px !important;
}
.mat-slide-toggle-thumb {
    height: 16px !important;
    width: 16px !important;
    border-radius: 50% !important;
    top: 6px !important;
    left: 3px !important;
}
.mat-slide-toggle-thumb{
    position: relative;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb{
    background: #ffffff !important;
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
.white-bg{
    background: #ffffff;
    margin: 2vh calc(10vw - 24px);
    padding: 2.5rem;
    border-radius: 10px;
}
.last-update{
    font-family: Poppins;
    font-size: 0.87rem;
    font-weight: 500;
    line-height: 1.286rem;
    text-align: left;
    opacity: 0.75;
    color: #5d6d7a;
}
::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 10px;
  background-color: #5d6d7a00;
}

::-webkit-scrollbar
{
  width: 5px;
  height: 7px;
  background-color: #5d6d7a00;
}

::-webkit-scrollbar-thumb
{
  border-radius: 10px;
//   -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #5D6D7A;
}
.highlight {
    background-color: yellow;
    font: inherit;
  }
  .mat-cell, .mat-footer-cell {
    font-size: 1rem !important;
}
h6{
    font-size: 1rem;
    margin-bottom: 1rem !important;
}
.data-filling-header {
    font-size: 1.16rem !important;
}
th.mat-header-cell, td.mat-cell, td.mat-footer-cell{
    padding-right: 8px !important;
}
@media screen and (max-width: 1300px){
    .content-padding {
        padding: 2vh 5vw;
    }
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}